<script setup>
import { useApp } from "~/composables/useApp";

const { htmlElements } = useApp();

const isActiveTab = (name) => htmlElements.appTabsEl?.currentTab?.name == name;
</script>
<template>
  <div class="app-footer">
    <v-btn
      @click="() => htmlElements.appTabsEl.goNext('home')"
      icon="sm"
      class="tab icon-size"
      :class="{ active: isActiveTab('home') }"
    >
      <div class="d-flex-column">
        <div class="justify-center icon-wrapper">
          <img src="/icons/bible-icon-v2.svg" alt="bible icon" class="svg-icon" />
        </div>
        <div class="icon-label-el mt-1">{{ transl("Bible") }}</div>
      </div>
    </v-btn>
    <v-btn
      @click="useEvent('navigation.opensearch')"
      icon="sm"
      class="tab icon-size"
      :class="{ active: isActiveTab('search') }"
    >
      <div class="d-flex-column">
        <div class="justify-center icon-wrapper">
          <img src="/icons/search-icon-v2.svg" alt="search icon" class="svg-icon" />
        </div>
        <div class="icon-label-el mt-1">{{ transl("Search") }}</div>
      </div>
    </v-btn>
    <v-btn
      @click="useEvent('navigation.opengallery')"
      icon="sm"
      class="tab icon-size"
      :class="{ active: isActiveTab('gallery') }"
    >
      <div class="d-flex-column">
        <div class="justify-center icon-wrapper">
          <img src="/icons/gallery-icon-v2.svg" alt="gallery icon" class="svg-icon" />
        </div>
        <div class="icon-label-el mt-1">{{ transl("Gallery") }}</div>
      </div>
    </v-btn>

    <v-btn
      @click="useEvent('navigation.openbibleplans')"
      icon="sm"
      class="tab icon-size"
      :class="{ active: isActiveTab('bibleplans') }"
    >
      <div class="d-flex-column">
        <div class="justify-center icon-wrapper">
          <img src="/icons/today-icon-v2.svg" alt="today icon" class="svg-icon" />
        </div>
        <div class="icon-label-el mt-1">{{ transl("Plan") }}</div>
      </div>
    </v-btn>
  </div>
</template>
<style scoped>
.icon-label-el {
  font-family: "Helvetica Regular";
  font-weight: 400;
  font-size: 11px;
  line-height: 164%;
  text-align: center;
  color: #292937;
}

.app-footer {
  width: 100%;
  height: var(--footer-height);
  background-color: var(--root-white);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;

  max-width: var(--width-mobile);
  left: 50%;
  transform: translate(-50%, 0%);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  flex-shrink: 0;
  max-width: var(--screen-max-width);
}
.icon-size.v-btn {
  position: relative;
}
.app-footer .v-btn.active:before {
  content: "";
  position: absolute;
  width: 3.5px;
  height: 3.5px;
  border-radius: 50%;
  bottom: 2px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tab {
  color: white;
  width: auto;
  display: flex;
  padding: 10px 20px;
  /* margin: 10px; */
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  transition: color 0.5s, background-color 0.5s;
  /* border-radius: 10px; */

  cursor: pointer;
  user-select: none;
}

.tab:hover {
  /* border-radius: 10px;
    background-color: #d0d4da45; */
}

.tab:active {
  /* border-radius: 10px; */
  background-color: #d0d4daec;
}

.icon {
  width: 30px;
}
/* https://angel-rs.github.io/css-color-filter-generator/ */
.active .svg-icon {
  filter: brightness(0) saturate(100%) invert(56%) sepia(75%) saturate(1893%)
    hue-rotate(131deg) brightness(91%) contrast(105%);
}

.active .icon-label-el {
  color: var(--root-green);
}

.icon-wrapper {
  position: relative;
  width: 50px;
}

/* .active .icon-wrapper::before {
  content: " ";
  position: absolute;
  left: -5px;
  top: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--root-green);
  transform: translate3d(0, -50%, 0);
} */

.svg-icon {
  width: 22px;
}
.icon-label-el {
  font-size: 12px;
}
</style>
